import { useStaticQuery, graphql } from 'gatsby'
import { get, lowerCase } from 'lodash-es'


export const getSiteConfig = (lang) => {
  const { allPrismicSiteConfig, site } = useStaticQuery(
    graphql`
      query SiteConfig {
        site {
          siteMetadata {
            siteUrl
            author
            languages
            defaultLanguage
          }
        }
        allPrismicSiteConfig {
          nodes {
            lang
            data {
              title
              meta_description
              meta_image {
                url
              }
              meta_image_square {
                url
              }
              nav {
                link {
                  uid
                  lang
                  link_type
                  slug
                  target
                  type
                  type
                  url
                }
                title
              }
              header_title
              nav_header {
                link {
                  uid
                  lang
                  link_type
                  slug
                  target
                  type
                  type
                  url
                }
                title
              }
              cookie_policy {
                url
                id
                link_type
              }
              footer_columns {
                content {
                  html
                  raw
                  text
                }
                column_width
                align
              }
              footer_notes {
                html
                raw
                text
              }
              newsletter_heading
              newsletter_description {
                html
              }
              newsletter_form_url
              newsletter_fields {
                field_name
                field_type
                field_label
                required
              }
              newsletter_submit_label
              social_links {
                username
                type
              }
              t_cookie_notice
              t_learn_more
              t_decline
              t_allow_cookies
            }
          }
        }
      }
    `
  )

  let siteConfig = null

  const config = allPrismicSiteConfig.nodes.filter(node => node.lang === lang)
  // The only time the config should be empty is when the site config for 
  // a language isn't set up correctly
  if (config && config.length > 0) {
    const { data } = config[0]
    const navArray = []
    if (data.nav) {
      data.nav.forEach((item, index) => {
        navArray.push({
          id: `nav-item-${index}`,
          title: item.title,
          url: item.link,
        })
      })
    }

    const navHeaderArray = []
    if (data.nav_header) {
      data.nav_header.forEach((item, index) => {
        navHeaderArray.push({
          id: `nav-item-${index}`,
          title: item.title,
          url: item.link,
        })
      })
    }

    const footerArray = []
    if (data.footer_columns) {
      data.footer_columns.forEach((item, index) => {
        footerArray.push({
          id: `footer-column-${index}`,
          content: get(item, 'content'),
          align: lowerCase(item.align),
          column_width: lowerCase(item.column_width),
        })
      })
    }

    const newsletterFieldArray = []
    if (data.newsletter_fields) {
      data.newsletter_fields.forEach((item, index) => {
        newsletterFieldArray.push({
          id: index,
          type: lowerCase(item.field_type),
          name: item.field_name,
          required: item.required === 'True',
          label: item.field_label,
        })
      })
    }

    const socialLinksArray = []
    if (data.social_links) {
      data.social_links.forEach((item, index) => {
        socialLinksArray.push({
          id: `social-links-${index}`,
          type: lowerCase(item.type),
          username: item.username,
        })
      })
    }

    const translations = {}
    Object.keys(data).forEach(k => {
      if (k.substr(0, 2) === "t_") {
        const key = k.substr(2);
        translations[key] = data[k]
      }
    })

    siteConfig = {
      title: get(data, 'title'),
      headerTitle: get(data, 'header_title'),
      metaDescription: get(data, 'meta_description'),
      shareImage: get(data, 'meta_image.url'),
      shareImageSquare: get(data, 'meta_image_square.url'),
      acknowledgmentStatement: get(data, 'acknowledgment_statement.html'),
      nav: navArray,
      navHeader: navHeaderArray,
      footer: footerArray,
      footerNotes: get(data, 'footer_notes'),
      newsletterHeading: get(data, 'newsletter_heading'),
      newsletterDescription: get(data, 'newsletter_description.html'),
      newsletterActionURL: get(data, 'newsletter_form_url'),
      newsletterFields: newsletterFieldArray,
      newsletterSubmitLabel: get(data, 'newsletter_submit_label'),
      socialLinks: socialLinksArray,
      translations,
      cookiePolicy: get(data, 'cookie_policy')
    }
  } else {
    siteConfig = {
      title: '',
      metaDescription: null,
      shareImage: null,
      acknowledgmentStatement: null,
      nav: [],
      navHeader: [],
      footer: [],
      footerNotes: null,
      newsletterHeading: null,
      newsletterDescription: null,
      newsletterActionURL: null,
      newsletterFields: [],
      newsletterSubmitLabel: null,
      socialLinks: [],
    }
  }

  siteConfig.siteMetadata = site.siteMetadata
  return siteConfig
}
