import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import styles from './menu.module.scss'
import Link from '../link'
import { linkResolver } from '../../prismic/utils/link-resolver'

const isPartiallyActive = ({ isPartiallyCurrent }) => {
  return isPartiallyCurrent ? { className: styles.el__nav__link__active } : null
}

const NavLink = props => <Link getProps={isPartiallyActive} {...props} />

const Menu = ({ navItems, open, onClickNav, children }) => {
  const [hovered, setHovered] = useState(null)
  const ani = {
    hidden: {
      x: '-100%',
      transition: { duration: 0.6, ease: 'backOut' },
    },
    visible: {
      x: '0%',
      transition: { duration: 0.3, ease: 'easeOut' },
    },
  }
  const listAni = {
    hidden: { transition: { staggerChildren: 0.05, staggerDirection: -1 } },
    visible: { transition: { staggerChildren: 0.07, delayChildren: 0.1 } },
  }

  const itemAni = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
    deactivated: { opacity: 0.4 },
  }

  const onMouseOver = idx => {
    setHovered(idx)
  }

  const onMouseOut = () => {
    setHovered(null)
  }

  const getMotionState = idx => {
    let state = null
    if (hovered === idx) {
      state = 'visible'
    } else if (hovered !== null) {
      state = 'deactivated'
    }
    return state
  }

  return (
    <motion.div
      className={styles.el}
      animate={open ? 'visible' : 'hidden'}
      variants={ani}
    >
      <motion.ul variants={listAni} className={styles.el__nav}>
        {navItems.map((item, idx) => (
          <motion.li
            variants={itemAni}
            className={styles.el__nav__item}
            key={item.id}
            onHoverStart={() => onMouseOver(idx)}
            onHoverEnd={() => onMouseOut(idx)}
            animate={getMotionState(idx)}
          >
            {item.url.uid ? (
              <NavLink
                onClick={() => onClickNav()}
                // onMouseOver={() => onMouseOver(idx)}
                // onMouseOut={() => onMouseOut(idx)}
                to={linkResolver(item.url)}
                className={styles.el__nav__link}
              >
                {item.title}
              </NavLink>
            ) : (
              <span className={styles.el__nav__link} />
            )}
          </motion.li>
        ))}
        <motion.div
          variants={itemAni}
          className={styles.el__nav__item_secondary}
        >
          {children}
        </motion.div>
      </motion.ul>
    </motion.div>
  )
}

Menu.defaultProps = {
  navItems: [],
  open: false,
  onClickNav: () => {},
  children: null,
}

Menu.propTypes = {
  open: PropTypes.bool,
  navItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      title: PropTypes.string,
      url: PropTypes.shape({
        uid: PropTypes.string,
        lang: PropTypes.string,
      }),
    })
  ),
  onClickNav: PropTypes.func,
  children: PropTypes.node,
}

export default Menu
