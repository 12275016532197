import React from 'react'
import PropTypes from 'prop-types'
import styles from './social-links.module.scss'
import SocialIcon from '../social-icon'

const SocialLinks = ({ items }) => {
  const networks = [
    {
      id: 'facebook',
      title: 'Facebook',
      url: username => `https://facebook.com/${username}`,
    },
    {
      id: 'instagram',
      title: 'Instagram',
      url: username => `https://instagram.com/${username}`,
    },
    {
      id: 'linkedin',
      title: 'LinkedIn',
      url: username => `https://linkedin.com/${username}`,
    },
    {
      id: 'twitter',
      title: 'Twitter',
      url: username => `https://twitter.com/${username}`,
    },
    {
      id: 'vimeo',
      title: 'Vimeo',
      url: username => `https://vimeo.com/${username}`,
    },
    {
      id: 'youtube',
      title: 'YouTube',
      url: username => `https://youtube.com/user/${username}`,
    },
  ]

  return (
    <ul className={styles.el}>
      {items.map(item => {
        const match = networks.find(network => network.id === item.type)

        if (match !== undefined) {
          return (
            <li key={item.id} className={styles.el__item}>
              <a
                href={match.url(item.username)}
                target="_blank"
                rel="noopener noreferrer"
                aria-label={match.title}
              >
                <SocialIcon type={item.type} />
              </a>
            </li>
          )
        }
        return undefined
      })}
    </ul>
  )
}

SocialLinks.defaultProps = {
  items: [],
}

SocialLinks.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.string,
      username: PropTypes.string,
    })
  ),
}

export default SocialLinks
