import React, { useState } from 'react'
import PropTypes from 'prop-types'
import MailchimpSubscribe from 'react-mailchimp-subscribe'

import styles from './newsletter-signup.module.scss'
import Container from '../container'
import RichText from '../rich-text'
import Indent from '../indent'

const NewsletterSignup = props => {
  const { heading, description, submitLabel, actionURL, fields } = props
  const [inputs, setInputs] = useState({})
  const [errors, setErrors] = useState([])

  const checkValidEmail = email => {
    // https://stackoverflow.com/a/46181
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    if (regex.test(String(email).toLowerCase())) {
      return true
    }
    return false
  }

  const handleSubmit = (event, subscribe) => {
    if (event) {
      event.preventDefault()

      // let errors = false
      const errorMsgs = []

      fields.forEach(field => {
        if (field.required && !inputs[field.name]) {
          errorMsgs.push('Please fill out required fields.')
        } else if (
          field.type === 'email' &&
          !checkValidEmail(inputs[field.name])
        ) {
          errorMsgs.push('Your email address is invalid.')
        }
      })

      if (!errorMsgs.length) {
        subscribe(inputs)
      } else {
        setErrors(errorMsgs)
      }
    }
  }

  const handleInputChange = event => {
    event.persist()
    setErrors([])
    setInputs(i => ({
      ...i,
      [event.target.name]: event.target.value,
    }))
  }

  return (
    <div className={styles.el}>
      <Container>
        <Indent>
          <div className={styles.el__container}>
            <div className={styles.el__side}>
              <h3 className={styles.el__heading}>{heading}</h3>
            </div>
            <div className={styles.el__main}>
              <div className={styles.el__description}>
                <RichText render={description.raw} />
              </div>
              {actionURL && (
                <MailchimpSubscribe
                  url={actionURL}
                  render={({ subscribe, status, message }) => (
                    <div className={styles.el__form}>
                      <div className={styles.el__messages}>
                        {status === 'sending' && (
                          <div
                            className={styles.el__message}
                            data-type="sending"
                          >
                            Sending...
                          </div>
                        )}
                        {status === 'error' && (
                          <div className={styles.el__message} data-type="error">
                            <RichText render={message.raw} />
                          </div>
                        )}
                        {status === 'success' && (
                          <div
                            className={styles.el__message}
                            data-type="success"
                          >
                            <RichText render={message.raw} />
                          </div>
                        )}
                        {errors.map(error => {
                          return (
                            <div
                              className={styles.el__message}
                              key={error}
                              data-type="error"
                            >
                              {error}
                            </div>
                          )
                        })}
                      </div>
                      <div className={styles.el__form__inner}>
                        {fields.map(item => {
                          return (
                            <input
                              name={item.name}
                              key={item.id}
                              type={item.type}
                              placeholder={item.label}
                              onChange={handleInputChange}
                              className={styles.el__field}
                            />
                          )
                        })}
                        <button
                          type="submit"
                          className={styles.el__submit}
                          onClick={event => {
                            handleSubmit(event, subscribe)
                          }}
                        >
                          {submitLabel}
                        </button>
                      </div>
                    </div>
                  )}
                />
              )}
            </div>
          </div>
        </Indent>
      </Container>
    </div>
  )
}

NewsletterSignup.defaultProps = {
  heading: '',
  description: '',
  submitLabel: '',
  actionURL: '',
  fields: [],
}

NewsletterSignup.propTypes = {
  heading: PropTypes.string,
  description: PropTypes.string,
  submitLabel: PropTypes.string,
  actionURL: PropTypes.string,
  fields: PropTypes.array,
}

NewsletterSignup.propTypes = {}

export default NewsletterSignup
