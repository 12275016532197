import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Link from '../link'
import styles from './language-selector.module.scss'

const isPartiallyActive = ({ href, isPartiallyCurrent, isCurrent }) => {
  // Some extra code here to deal with when the language is the root language e.g. "/"
  let isActive = false

  // Not really sure why I need this fix
  // const href = props.href.replace('/*', '/')

  if (href === '/') {
    if (isPartiallyCurrent && isCurrent) {
      isActive = true
    }
  } else {
    isActive = isPartiallyCurrent
  }

  return isActive ? { className: styles.el__nav__activeLink } : null
}

const LANGUAGE_CODE_MAP = {
  'en-gb': 'Eng (UK)',
  'en-us': 'Eng',
  de: 'Deutsch',
  nl: 'Nederlands',
  fr: 'Français',
  es: 'Español',
  'es-ar': 'Español (AR)',
  ca: 'Català',
  it: 'Italiano',
  'pt-pt': 'Português (PT)',
  'pt-br': 'Português (BR)',
  no: 'Norsk',
  fi: 'Suomi',
  sv: 'Svenska',
  da: 'Dansk',
  cs: 'Čeština',
  hu: 'Magyar',
  ro: 'Română',
  ja: '日本語',
  'zh-cn': '中文',
  'zh-tw': '繁體中文',
  pl: 'Polski',
  el: 'Ελληνικά',
  ru: 'Русский',
  tr: 'Türkçe',
  bg: 'Български',
  ar: 'العربية',
  ko: '한국어',
  he: 'עברית',
  lv: 'Latviski',
  uk: 'Українська',
  id: 'Bahasa Indonesia',
  ms: 'Bahasa Malaysia',
  th: 'ภาษาไทย',
  et: 'Eesti',
  hr: 'Hrvatski',
  lt: 'Lietuvių',
  sk: 'Slovenčina',
  sr: 'Srpski',
  sl: 'Slovenščina',
  vi: 'Tiếng Việt',
  tl: 'Filipino',
  is: 'Íslenska',
}

const getLanguageNameFromCode = code => {
  let name = code
  if (LANGUAGE_CODE_MAP[code]) {
    name = LANGUAGE_CODE_MAP[code]
  } else {
    const short = code.substr(0, 2)
    if (LANGUAGE_CODE_MAP[short]) {
      name = LANGUAGE_CODE_MAP[short]
    }
  }
  return name
}

const formatCode = code => code.substr(0, code.indexOf('-'))

const LanguageSelector = ({ languageMenuItems, className }) => {
  return (
    <div className={classNames(styles.el__nav__list, className)}>
      <ul className={styles.el__nav__list__inner}>
        {languageMenuItems.map((item, idx) => (
          <li className={styles.el__nav__item} key={item.code}>
            {idx !== 0 &&
              <span className={styles.el__nav__item__spacer}>|</span>
            }
            <Link
              to={item.url}
              getProps={isPartiallyActive}
              title={getLanguageNameFromCode(item.code)}
              className={styles.el__nav__link}
            >
              {formatCode(item.code)}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

LanguageSelector.defaultProps = {
  className: null,
}

LanguageSelector.propTypes = {
  languageMenuItems: PropTypes.array.isRequired,
  className: PropTypes.string,
}

export default LanguageSelector
