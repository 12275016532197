import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { linkResolver } from '../../prismic/utils/link-resolver'

const validURL = str => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ) // fragment locator
  return !!pattern.test(str)
}

const getPageFormater = (siteTitle, pageUid) => {
  return pageTitle => {
    let title = siteTitle
    if (pageTitle && pageUid !== 'home') {
      title = `${pageTitle} | ${title}`
    }
    return title
  }
}

export const parseMetadataFromPrismic = (pageUid, node, lang, site) => {
  const formatter = getPageFormater(site.title, pageUid)
  const { title } = node
  const metaTitle =
    node.meta_title && node.meta_title.length > 0 ? node.meta_title : title
  const description =
    node.meta_description && node.meta_description.length > 0
      ? node.meta_description
      : null
  const canonicalPathname =
    node.canonical_url && node.canonical_url.length > 0
      ? node.canonical_url
      : null

  const social = {}
  social.description = node.card_description || description // Set the main description as a default
  social.image = node.card_imageSharp
    ? node.card_imageSharp.childImageSharp.fixed.src
    : site.shareImage
  social.imageSquare = node.card_image_squareSharp
    ? node.card_image_squareSharp.childImageSharp.fixed.src
    : site.shareImageSquare
  social.title = formatter(metaTitle || title)

  return {
    title: formatter(title),
    metaTitle: formatter(metaTitle),
    description,
    social,
    canonicalPathname,
    lang,
  }
}

const SEO = props => {
  const {
    title,
    metaTitle,
    siteTitle,
    host,
    lang,
    defaultLang,
    social,
    pathname,
    alternateLanguages,
    canonicalPathname,
    article,
  } = props

  const seo = {
    title: metaTitle || title,
    url: `${host}${pathname || '/'}`,
  }

  let canonicalUrl = null
  if (canonicalPathname) {
    if (validURL(canonicalPathname)) {
      canonicalUrl = canonicalPathname
    } else {
      canonicalUrl = `${host}/${canonicalPathname.replace(/^\//, '')}`
    }
  } else if (alternateLanguages && alternateLanguages.length > 0) {
    // Search alternates for default lang (eng) as canonical
    const defaultLanguage = alternateLanguages.filter(
      language => language.lang === defaultLang
    )
    if (defaultLanguage.length > 0) {
      canonicalUrl = `${host}${linkResolver(defaultLanguage)}`
    }
  }

  return (
    <>
      <Helmet title={seo.title} defaultTitle={siteTitle}>
        <html lang={lang} />
        <meta charSet="utf-8" />
        {social.description && (
          <meta name="description" content={social.description} />
        )}
        {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
        {alternateLanguages &&
          alternateLanguages.map(language =>
            language.lang !== defaultLang ? (
              <link
                key={language.lang}
                rel="alternate"
                hrefLang={language.lang}
                href={`${host}${linkResolver(language)}`}
              />
            ) : null
          )}
        {seo.url && <meta property="og:url" content={seo.url} />}
        {(article ? true : null) && (
          <meta property="og:type" content="article" />
        )}
        {social.title && <meta property="og:title" content={social.title} />}
        {social.description && (
          <meta property="og:description" content={social.description} />
        )}
        {social.image && <meta property="og:image" content={social.image} />}
        <meta name="twitter:card" content="summary" />
        {social.title && <meta name="twitter:title" content={social.title} />}
        {social.description && (
          <meta name="twitter:description" content={social.description} />
        )}
        <link rel="image_src" href={social.imageSquare} />
        <script type="application/ld+json">
          {`
            '@context': 'https://schema.org',
            '@type': 'Company',
            url: '${seo.url}',
            name: '${seo.title}',
          `}
        </script>
      </Helmet>
    </>
  )
}

export default SEO
SEO.propTypes = {
  title: PropTypes.string.isRequired,
  metaTitle: PropTypes.string,
  lang: PropTypes.string.isRequired,
  defaultLang: PropTypes.string.isRequired,
  host: PropTypes.string.isRequired,
  siteTitle: PropTypes.string,
  canonicalPathname: PropTypes.string,
  alternateLanguages: PropTypes.array,
  social: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    imageSquare: PropTypes.string,
  }),
  image: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
}

SEO.defaultProps = {
  social: {},
  image: null,
  siteTitle: null,
  metaTitle: null,
  canonicalPathname: null,
  alternateLanguages: null,
  pathname: null,
  article: false,
}
