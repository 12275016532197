import React from 'react'
import PropTypes from 'prop-types'
import { RichText as PrismicRichText } from 'prismic-reactjs'

import styles from './rich-text.module.scss'

const RichText = props => {
  const { render, headingStyles, listStyles } = props
  return (
    <div
      className={styles.el}
      data-headings={headingStyles}
      data-lists={listStyles}
    >
      <PrismicRichText render={render} />
    </div>
  )
}

RichText.defaultProps = {
  headingStyles: true,
  listStyles: true,
}

RichText.propTypes = {
  render: PropTypes.array.isRequired,
  headingStyles: PropTypes.bool,
  listStyles: PropTypes.bool,
}

export default RichText
