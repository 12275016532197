import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './container.module.scss'

const Container = ({ children, className }) => (
  <div className={classNames(styles.el, className)}>{children}</div>
)

Container.defaultProps = {
  children: undefined,
  className: undefined,
}

Container.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

export default Container
