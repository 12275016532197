// link resolver
// requires: type, uid
// as the project grows, this function should be updated according to the routes.


const defaultLang = process.env.PRISMIC_DEFAULT_LANGUAGE || 'es-es'

const DEFAULT_LANGUAGE_ROOT = `/`
exports.DEFAULT_LANGUAGE_ROOT = DEFAULT_LANGUAGE_ROOT

const trimLanguageCode = code => {
  let result = null
  if (code === defaultLang) {
    result = ''
  } else {
    result = code.substr(0, code.indexOf('-'))
  }
  return result
}
exports.trimLanguageCode = trimLanguageCode

exports.linkResolver = page => {
  let langPrefix = DEFAULT_LANGUAGE_ROOT
  const lang = page.lang ? page.lang : defaultLang
  if (lang !== defaultLang) {
    langPrefix = `/${trimLanguageCode(lang)}/`
  }

  // default route
  const path = page.uid === 'home' ? '' : `${page.uid}/`

  // Return the path with trailing slashes pulled out
  return `${langPrefix}${path}`
}