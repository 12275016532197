// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-prismic-templates-flexible-prismic-jsx": () => import("./../src/prismic/templates/flexible.prismic.jsx" /* webpackChunkName: "component---src-prismic-templates-flexible-prismic-jsx" */),
  "component---src-prismic-templates-basic-page-prismic-jsx": () => import("./../src/prismic/templates/basic-page.prismic.jsx" /* webpackChunkName: "component---src-prismic-templates-basic-page-prismic-jsx" */),
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-preview-jsx": () => import("./../src/pages/preview.jsx" /* webpackChunkName: "component---src-pages-preview-jsx" */)
}

