import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './indent.module.scss'

const Indent = ({ children, indent, className }) => (
  <div className={classNames(styles.el, className)} data-indent={indent}>
    {children}
  </div>
)

Indent.defaultProps = {
  children: undefined,
  indent: 1,
  className: '',
}

Indent.propTypes = {
  children: PropTypes.node,
  indent: PropTypes.number,
  className: PropTypes.string,
}

export default Indent
