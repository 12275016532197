import React, { useEffect, useContext } from 'react'
import PropTypes from 'prop-types'

import 'cookieconsent/build/cookieconsent.min.css'
import { TranslationContext } from '../../layout/layout'

const CookieBanner = ({ cookiePolicy }) => {
  const t = useContext(TranslationContext)
  useEffect(() => {
    if (window) {
      import('cookieconsent/build/cookieconsent.min.js').then(() => {
        window.cookieconsent.initialise({
          container: document.getElementById('cookie-consent'),
          palette: {
            popup: {
              background: '#edeff5',
              text: '#838391',
            },
            button: {
              background: '#4b81e8',
            },
          },
          location: true,
          law: {
            countryCode: 'ES',
            // regionalLaw: true,
          },
          content: {
            // header: 'Cookies used on the website!',
            message: t.cookie_notice,
            dismiss: t.allow_cookies,
            allow: t.allow_cookies,
            deny: t.decline,
            link: t.learn_more,
            href: cookiePolicy.url,
            // close: '&#x274c;',
            // policy: 'Cookie Policy',
            // target: '_blank',
          },
          // type: 'opt-in',
        })
      })
    }
  }, [])

  return <div id="cookie-consent" />
}

CookieBanner.defaultProps = {}

CookieBanner.propTypes = {
  cookiePolicy: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
}

export default CookieBanner
